import CustomBoardPage from 'pages/CustomBoardPage';
import DesktopErrorPage from 'pages/DesktopErrorPage';
import ErrorPage from 'pages/ErrorPage';
import FloorPage from 'pages/FloorPage';
import InitialLoadingPage from 'pages/InitialLoadingPage';
import LoginPage from 'pages/LoginPage';
import MainPage from 'pages/MainPage';
import NoLoginCafeShowPage from 'pages/no-login/NoLoginCafeShowPage';
import NoLoginWisPage from 'pages/no-login/NoLoginWisPage';
import NotFound from 'pages/NotFound';
import ResolutionErrorPage from 'pages/ResolutionErrorPage';
import TenantPage from 'pages/TenantPage';
import { ReactElement } from 'react';
import { PathType } from 'types/common/common.type';

export const ROUTES: {
  path: PathType;
  screen: ReactElement;
  isProtected: boolean;
  isError: boolean;
}[] = [
  // { path: PathType.login, isProtected: false, isError: false, screen: <LoginPage /> },
  // { path: PathType.noLoginWis, isProtected: false, isError: false, screen: <NoLoginWisPage /> },
  // { path: PathType.noLoginCafeShow, isProtected: false, isError: false, screen: <NoLoginCafeShowPage /> },

  { path: PathType.notFound, isProtected: false, isError: true, screen: <NotFound /> },
  { path: PathType.error, isProtected: false, isError: true, screen: <ErrorPage /> },
  { path: PathType.desktopError, isProtected: false, isError: true, screen: <DesktopErrorPage /> },
  { path: PathType.resolutionError, isProtected: false, isError: true, screen: <ResolutionErrorPage /> },

  { path: PathType.loading, isProtected: false, isError: false, screen: <InitialLoadingPage /> },

  { path: PathType.home, isProtected: true, isError: false, screen: <MainPage /> },
  { path: PathType.floor, isProtected: true, isError: false, screen: <FloorPage /> },
  { path: PathType.tenant, isProtected: true, isError: false, screen: <TenantPage /> },
  { path: PathType.customBoard, isProtected: true, isError: false, screen: <CustomBoardPage /> },
];

export const ROUTES_WEB: {
  path: PathType;
  screen: ReactElement;
  isProtected: boolean;
  isError: boolean;
}[] = [
  { path: PathType.loading, isProtected: false, isError: false, screen: <InitialLoadingPage /> },
  { path: PathType.home, isProtected: true, isError: false, screen: <MainPage /> },
  { path: PathType.floor, isProtected: true, isError: false, screen: <FloorPage /> },
  { path: PathType.tenant, isProtected: true, isError: false, screen: <TenantPage /> },
  { path: PathType.customBoard, isProtected: true, isError: false, screen: <CustomBoardPage /> },
  { path: PathType.error, isProtected: false, isError: true, screen: <ErrorPage /> },
];
