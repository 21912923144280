import React from 'react';
import { Outlet } from 'react-router-dom';
import useDomainStore from 'stores/domain';
import styles from './Layout.module.scss';
import Footer from './footer/Footer';

const Layout = () => {
  const domainStore = useDomainStore();

  return domainStore.domainType === 'WEB' ? (
    <div className={styles.web_layout}>
      <Outlet /> {/* 중첩된 Route가 여기에 렌더링됨 */}
      <Footer />
    </div>
  ) : (
    <div className={styles.layout}>
      <Outlet /> {/* 중첩된 Route가 여기에 렌더링됨 */}
      <Footer />
    </div>
  );
};

export default Layout;
