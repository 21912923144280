import { EndPoint } from 'data/endPoints';
import useDomainStore from 'stores/domain';
import useLanguageStore from 'stores/languages';
import { CustomField } from 'types/feature/customField.type';
import useAxios from 'utils/axios/fetchAxios';
import useNoAuthAxios from 'utils/axios/noAuthFetchAxios';

const useCustomField = () => {
  // store
  const langStore = useLanguageStore();
  const domainStore = useDomainStore();

  // hook
  const { api } = useAxios();
  const noAuthApiManager = useNoAuthAxios();

  /**
   * @desc custom fields 조회
   * @param workspaceId
   * @returns
   */
  const getCustomField = async (poiContentId: string, pointId: string) => {
    // domainType 에 따라 api 분기 WEB
    if (domainStore.domainType === 'WEB') {
      const data = await noAuthApiManager.api.get<CustomField>(
        EndPoint.customFieldsByMobile.replace(':poi-content-id', poiContentId),
        {
          params: { languageCode: langStore.currentLang.toUpperCase(), pointId },
        },
      );

      return data;
    }

    // domainType 에 따라 api 분기 DESKTOP
    const data = await api.get<CustomField>(EndPoint.customFields.replace(':poi-content-id', poiContentId), {
      params: { languageCode: langStore.currentLang.toUpperCase(), pointId },
    });

    return data;
  };

  return {
    getCustomField,
  };
};

export default useCustomField;
