import MapDraw from 'map/MapDraw';
import { clearMap, clearMyLocation } from './common/clear';
import { validMap } from './common/valid';

// 내 위치 마커 표시(ANI)
export const showMyLocation = async () => {
  validMap();

  await MapDraw.map.mylocation.set({
    x: MapDraw.machine.coordinate.x,
    y: MapDraw.machine.coordinate.y,
    floorId: MapDraw.machine.floor.id,
    iconOption: {
      positionZ: 0,
      iconUrl: '/assets/icon/me.svg',
      width: 20,
      height: 20,
    },
    animate: {
      color: '#4869df',
      opacity: 0.2,
      desireScale: 3,
    },
    onActive: true, // 타층 이동 후 다시 돌아왔을 때 내위치마커 노출 여부 설정
    gpsOption: {
      leftCourseDistance: 50,
      willArriveRadius: 100,
      arrivedRadius: 50,
    },
  });
};

// 내 위치 마커 표시(NO ANI)
export const showMyLocationNoAni = async () => {
  validMap();

  await MapDraw.map.mylocation.set({
    x: MapDraw.machine.coordinate.x,
    y: MapDraw.machine.coordinate.y,
    floorId: MapDraw.machine.floor.id,
    iconOption: {
      positionZ: 0,
      iconUrl: '/assets/icon/me.svg',
      width: 20,
      height: 20,
    },
    onActive: true, // 타층 이동 후 다시 돌아왔을 때 내위치마커 노출 여부 설정
    gpsOption: {
      leftCourseDistance: 50,
      willArriveRadius: 100,
      arrivedRadius: 50,
    },
  });
};

// 내 위치 마커로 이동
export const goToMyLocation = async () => {
  clearMap();

  await showMyLocation();

  // 5초 후 애니메이션 제거된 현재위치 마커 표시
  const timer = setTimeout(async () => {
    clearMyLocation();

    // 애니메이션 나오는 도중 층이 변경되었을 경우 현재위치 아이콘 나오지 않도록 처리
    await showMyLocationNoAni();

    clearTimeout(timer);
  }, 5000);
};
