import React from 'react';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import CustomFieldQr from 'component/common/qr/container/custom-field/CustomFieldQr';
import styles from './QrField.module.scss';

type Props = {
  field?: any;
  customField?: {
    title: string;
    content: string;
  };
};
const QrField = ({ field, customField }: Props) => {
  const colorSetStore = useColorSetStore();
  const langStore = useLanguageStore();

  return (
    <div className={styles.detail_qr}>
      {field && (
        <div className={styles.detail_qr}>
          {/* 제목 */}
          <div style={{ color: colorSetStore.text.item }} className={styles.field_title}>
            {field.title[langStore.currentLang]}
          </div>
          {/* 내용 */}
          <div className={styles.field_content_qr}>
            <div className={styles.content}>
              <CustomFieldQr url={field.content?.[langStore.currentLang]} />
            </div>
          </div>
        </div>
      )}
      {customField && (
        <div className={styles.detail_qr}>
          {/* 제목 */}
          <div style={{ color: colorSetStore.text.item }} className={styles.field_title}>
            {customField.title}
          </div>
          {/* 내용 */}
          <div className={styles.field_content_qr}>
            <div className={styles.content}>
              <CustomFieldQr url={customField.content} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QrField;
