import { useState } from 'react';
import { SearchTabType } from 'types/feature/tenantFilter.type';
import useDomainStore from 'stores/domain';
import TenantFilter from './filter/TenantFilter';
import TenantList from './tenant-list/TenantList';
import styles from './TenantContainer.module.scss';

const TenantContainer = () => {
  const domainStore = useDomainStore();
  const [currentFilter, setCurrentFilter] = useState(SearchTabType.tenant);

  return domainStore.domainType === 'WEB' ? (
    <div className={styles.container}>
      <TenantList currentFilter={currentFilter} />
      <TenantFilter currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />
    </div>
  ) : (
    <>
      <TenantList currentFilter={currentFilter} />
      <TenantFilter currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />
    </>
  );
};
export default TenantContainer;
