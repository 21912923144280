/* eslint-disable react/jsx-no-useless-fragment */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import useCategoryGroupStore from 'stores/categoryGroup';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import useMachineStore from 'stores/machine';
import useScreenTypeStore from 'stores/screenType';
import useTenantStore from 'stores/tenant';
import { SwiperSlide } from 'swiper/react';
import { CategoryGroupWithTenants } from 'types/common/category.type';
import { ScreenType } from 'types/common/common.type';
import { TenantWithPointId } from 'types/common/tenant.type';
import { SearchTabType } from 'types/feature/tenantFilter.type';
import { makeSlides } from 'utils/tenant/slide/makeSlides';
import useDomainStore from 'stores/domain';
import styles from './TenantList.module.scss';
import NoTenant from './no-tenant/NoTenant';
import TenantSlide from './slide/TenantSlide';
import CustomPagination from './swiper/pagination/CustomPagination';
import TenantItem from './swiper/tenant-item/TenantItem';

type Props = {
  currentFilter: SearchTabType;
};

const TenantList = ({ currentFilter }: Props) => {
  const screenTypeStore = useScreenTypeStore();
  const tenantStore = useTenantStore();
  const colorSetStore = useColorSetStore();
  const langStore = useLanguageStore();
  const categoryGroupStore = useCategoryGroupStore();
  const machineStore = useMachineStore();

  const [tenantsSlides, setTenantsSlides] = useState<TenantWithPointId[][]>([]);

  // 스크린타입별로 한 슬라이드에 들어갈 아이템 수를 조절
  const makeTenantsSlides = () => {
    // 정방형
    if (screenTypeStore.screenType === ScreenType.square) {
      setTenantsSlides(makeSlides(_.cloneDeep(tenantStore.filteredTenants), 6));

      return;
    }

    // 세로형
    if (screenTypeStore.screenType === ScreenType.verti) {
      setTenantsSlides(makeSlides(_.cloneDeep(tenantStore.filteredTenants), 10));
      return;
    }

    // 가로형
    setTenantsSlides(makeSlides(_.cloneDeep(tenantStore.filteredTenants), 12));
  };

  // 기본 테넌트로 세팅
  const initFilteredTenants = () => {
    if (currentFilter === SearchTabType.floor || currentFilter === SearchTabType.category) {
      const currentPointId = machineStore.machine?.point.id;

      if (categoryGroupStore.tenantSearchCategoriesMap && currentPointId) {
        const findCategory: CategoryGroupWithTenants | undefined =
          categoryGroupStore.tenantSearchCategoriesMap.get(currentPointId);

        if (findCategory) {
          categoryGroupStore.setCategoryTabs(findCategory.categoryGroups);
          categoryGroupStore.setCategoryList('TENANT_SEARCH', findCategory);

          tenantStore.setFilteredTenants(findCategory.tenants, langStore.currentLang);
        }
      }
    }
  };

  useEffect(() => {
    initFilteredTenants();
  }, [currentFilter]);

  useEffect(() => {
    makeTenantsSlides();
  }, [screenTypeStore.screenType, tenantStore.filteredTenants]);

  const domainStore = useDomainStore();
  return domainStore.domainType === 'WEB' ? (
    <div
      className={styles.web_container}
      style={{
        background: colorSetStore.list.bg,
      }}
    >
      <div className={styles.tenant_wrapper}>
        <TenantSlide>
          <>
            {tenantsSlides.length > 0 &&
              tenantsSlides.map((slide, index) => (
                <SwiperSlide key={index} className={styles.slides} virtualIndex={index}>
                  {slide.map((tenant, tenantIndex) => {
                    return <TenantItem key={tenantIndex} tenant={tenant} />;
                  })}
                </SwiperSlide>
              ))}
          </>
        </TenantSlide>

        {tenantsSlides.length < 1 && <NoTenant />}
      </div>

      {/* pagination */}
      <div className={`${styles.pagination_wrapper} ${tenantsSlides.length > 0 ? styles.visible : styles.invisible}`}>
        <CustomPagination />
      </div>
    </div>
  ) : (
    <div
      className={styles.container}
      style={{
        background: colorSetStore.list.bg,
      }}
    >
      <div className={styles.tenant_wrapper}>
        <TenantSlide>
          <>
            {tenantsSlides.length > 0 &&
              tenantsSlides.map((slide, index) => (
                <SwiperSlide key={index} className={styles.slides} virtualIndex={index}>
                  {slide.map((tenant, tenantIndex) => {
                    return <TenantItem key={tenantIndex} tenant={tenant} />;
                  })}
                </SwiperSlide>
              ))}
          </>
        </TenantSlide>

        {tenantsSlides.length < 1 && <NoTenant />}
      </div>

      {/* pagination */}
      <div className={`${styles.pagination_wrapper} ${tenantsSlides.length > 0 ? styles.visible : styles.invisible}`}>
        <CustomPagination />
      </div>
    </div>
  );
};

export default TenantList;
