import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * domainType, WebInitInfo 를 셋팅한다.
 */
const useExceptLink = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const exception = () => {
    if (location.pathname === '/cafeshow') {
      navigate(`/web/cafeshow`, { replace: true });
      window.location.reload(); // 새로고침
    }
  };

  useEffect(() => {
    exception();
  }, []);
};
export default useExceptLink;
