import { EndPoint } from 'data/endPoints';
import { Screensaver } from 'types/feature/screensaver.type';
import useAxios from 'utils/axios/fetchAxios';
import useNoAuthAxios from 'utils/axios/noAuthFetchAxios';
import useDomainStore from 'stores/domain';
import useWebInfoStore from 'stores/webInfo';
import useFetchScreensaver from '../useFetchScreensaver';

const useScreensaver = () => {
  // hook
  const { api } = useAxios();
  const noAuthApiManager = useNoAuthAxios();
  const { setSucceededMedia } = useFetchScreensaver();

  // store
  const domainStore = useDomainStore();
  const webInfoStore = useWebInfoStore();

  const getScreensaver = async () => {
    // domainType 에 따라 api 분기 WEB
    if (domainStore.domainType === 'WEB') {
      const pointId = webInfoStore.webInfo?.point.id;
      if (!pointId) return;
      const result = await noAuthApiManager.api.get<Screensaver[]>(EndPoint.screenByMobile, {
        params: {
          pointId,
          screenType: 'HORIZONTAL',
        },
      });

      if (result) {
        const clientResult = result.map((screensaver, index) => {
          return {
            title: screensaver.title,
            fileUri: screensaver.fileUri,
            fileType: screensaver.fileType,
            fileName: screensaver.fileName,
            sortOrder: index,
            id: screensaver.id,
          };
        });
        setSucceededMedia(clientResult);
      }
    } else {
      // domainType 에 따라 api 분기 DESKTOP
      const result = await api.get<Screensaver[]>(EndPoint.screen);

      if (result) {
        const clientResult = result.map((screensaver, index) => {
          return {
            title: screensaver.title,
            fileUri: screensaver.fileUri,
            fileType: screensaver.fileType,
            fileName: screensaver.fileName,
            sortOrder: index,
            id: screensaver.id,
          };
        });
        setSucceededMedia(clientResult);
      }
    }
  };

  return {
    getScreensaver,
  };
};

export default useScreensaver;
