import ButtonLoader from 'component/common/loading/button/ButtonLoader';
import { ModalType } from 'component/common/modal/container/ModalContainer';
import useCheckValidRoute from 'hooks/route/useCheckValideRoute';
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useMachineStore from 'stores/machine';
import useTenantStore from 'stores/tenant';
import { ButtonType } from 'types/common/button.type';
import { PathType } from 'types/common/common.type';
import useDomainStore from 'stores/domain';
import TenantQr from '../qr/TenantPopupQr';
import TenantButton from './TenantButton';
import styles from './TenantButtonList.module.scss';

type Props = {
  openModal: (type: ModalType) => void;
  closeModal: () => void;
};

const TenantButtonList = (props: Props) => {
  const location = useLocation();
  const machineStore = useMachineStore();
  const tenantStore = useTenantStore();
  const domainStore = useDomainStore();

  const { checkValidRoute, routeLoading, notAvailableNavi } = useCheckValidRoute();

  useLayoutEffect(() => {
    if (tenantStore.currentTenant) {
      checkValidRoute(tenantStore.currentTenant);
    }
  }, [tenantStore.currentTenant]);

  // weh 형일 때 tenant 와 일치하는 지 체크
  const webCheckTenant = () => {
    if (domainStore.domainType === 'WEB') {
      const parts = location.pathname.split('/').filter(Boolean);
      const path = parts[parts.length - 1];
      if (path === PathType.tenant) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <div className={styles.container}>
      {/* 로딩 */}
      {routeLoading ? (
        <ButtonLoader />
      ) : (
        <>
          {/* 모바일 보기 */}
          {machineStore.machine?.workspace.mobileUsed && <TenantQr />}

          {/* 버튼 */}
          <div className={styles.buttons_wrapper}>
            {/* 위치확인 */}
            {(location.pathname === `/${PathType.tenant}` || webCheckTenant()) && (
              <TenantButton {...props} type={ButtonType.location} />
            )}

            {/* 길찾기 */}
            {!notAvailableNavi && <TenantButton {...props} type={ButtonType.navigation} />}
          </div>
        </>
      )}
    </div>
  );
};

export default TenantButtonList;
