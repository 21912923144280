import { Web } from 'types/feature/webInfo.type';
import { create } from 'zustand';

type WebInfoStore = {
  webInfo: Web | null;
  setWebInfo: (webInfo: Web) => void;
};

const useWebInfoStore = create<WebInfoStore>((set, get) => ({
  webInfo: null,
  setWebInfo(webInfo: Web) {
    set({
      webInfo,
    });
  },
}));

export default useWebInfoStore;
