/* eslint-disable no-nested-ternary */
import { t } from 'i18next';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import useMachineStore from 'stores/machine';
import styles from './BusinessHour.module.scss';

const BusinessHour = () => {
  const machineStore = useMachineStore();
  const colorSetStore = useColorSetStore();
  const langStore = useLanguageStore();

  const textColor = colorSetStore.footer.text;
  const borderColor = `${`1px solid ${colorSetStore.footer.text}`}`;

  // 시작 시간 ~ 종료 시간
  const businessHour = `${machineStore.machine?.point.hoursStart} ~ ${machineStore.machine?.point.hoursEnd}`;

  return machineStore.machine?.point.hoursUsed ? (
    // 운영시간 사용하는 경우
    <div className={styles.container}>
      <div
        style={{
          border: borderColor,
          color: textColor,
        }}
        className={styles.box}
      >
        {t(`info.businessHours`)}
      </div>
      {machineStore.machine?.point.hoursType === 'DATE' ? (
        // DATE 타입
        <div style={{ color: textColor }} className={styles.hour}>
          {businessHour || ''}
        </div>
      ) : (
        // TEXT 타입
        <div style={{ color: textColor }} className={styles.hours_desc}>
          {machineStore.machine?.point.hoursText[langStore.currentLang]}
        </div>
      )}
    </div>
  ) : (
    // 운영시간 사용하지 않는 경우
    <> </>
  );
};

export default BusinessHour;
