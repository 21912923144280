import { EndPoint } from 'data/endPoints';
import useCategoryGroupStore from 'stores/categoryGroup';
import useDomainStore from 'stores/domain';
import useTenantStore from 'stores/tenant';
import useWebInfoStore from 'stores/webInfo';
import { CategoryFeature, CategoryGroupWithTenants, TenantCategoryGroup } from 'types/common/category.type';
import { LangCode } from 'types/common/language.type';
import { TenantWithPointId } from 'types/common/tenant.type';
import useAxios from 'utils/axios/fetchAxios';
import useNoAuthAxios from 'utils/axios/noAuthFetchAxios';
import { convertCategoryListToMap } from 'utils/category/convert/list-to-map/convertCategoryListToMap';

const useCategory = () => {
  // store
  const categoryGroupStore = useCategoryGroupStore();
  const tenantStore = useTenantStore();
  const domainStore = useDomainStore();
  const webInfoStore = useWebInfoStore();

  // hook
  const { api } = useAxios();
  const noAuthApiManager = useNoAuthAxios();

  // 카테고리 목록
  const getTenantCategoryGroups = async (feature?: CategoryFeature) => {
    // domainType 에 따라 api 분기
    if (domainStore.domainType === 'WEB') {
      const workspaceId = webInfoStore.webInfo?.workspace.id;
      if (!workspaceId) return;

      const result = await noAuthApiManager.api.get<TenantCategoryGroup[]>(EndPoint.tenantCategoryGroupsByMobile, {
        params: {
          feature,
          workspaceId,
        },
      });
      if (result) {
        return result;
      }
    } else {
      // domainType 에 따라 api 분기 DESKTOP
      const result = await api.get<TenantCategoryGroup[]>(EndPoint.tenantCategoryGroups, {
        params: {
          feature,
        },
      });
      if (result) {
        return result;
      }
    }
  };

  /**
   * 기능별(매장검색, 층별안내 편의시설, 편의시설 메뉴)로 매칭되는 카테고리 및 테넌트가 다르기 때문에
   * 각각 기능에 해당하는 카테고리 목록을 불러온 뒤,
   * 테넌트들을 기능별로 분류시키기 위한 코드입니다.
   */
  const getCategoriesAndTenantsByFeature = async (
    mainLang: LangCode,
    pointId: string,
    tenants: TenantWithPointId[],
  ) => {
    // 매장검색 카테고리
    const tenantSearchCategories: TenantCategoryGroup[] | undefined = await getTenantCategoryGroups('TENANT_SEARCH');

    // 층별안내 편의시설
    const mapFacilityCategories: TenantCategoryGroup[] | undefined = await getTenantCategoryGroups('MAP_FACILITY');

    //  category List를 Map으로 변환 (pointId 별로 다 때려넣음)
    const tenantSearchGroupsMap: Map<string, CategoryGroupWithTenants> = convertCategoryListToMap(
      tenantSearchCategories,
      mainLang,
    );
    const mapFacilityGroupsMap: Map<string, CategoryGroupWithTenants> = convertCategoryListToMap(
      mapFacilityCategories,
      mainLang,
    );

    // 전체 tenants 를 point 별로 카테고리에 테넌트를 넣는다
    tenants?.forEach(tenant => {
      // 매장검색용 전체 tenants
      const findSearchGroup: CategoryGroupWithTenants | undefined = tenantSearchGroupsMap.get(tenant.pointId);
      if (findSearchGroup) {
        // 1. 매장검색의 pointId 랑 tenantPointId 랑 같은지 확인한다.
        // 2. myPointSearchGroup 의 categoryGroupId 랑 tenant 의 categoryGroupId 이 같으면
        findSearchGroup.categoryGroups.forEach(category => {
          tenant.categoryGroups.forEach(tenantCategory => {
            if (category.id === tenantCategory.id) {
              // 3. 해당 category group 에 tenant 를 넣는다.
              // TODO: category group 별로 tenants 를 분리해서 담는다

              findSearchGroup.tenants.push(tenant);
            }
          });
        });
      }

      // 층별안내용 전체 tenants
      const findFacilityGroup: CategoryGroupWithTenants | undefined = mapFacilityGroupsMap.get(tenant.pointId);

      if (findFacilityGroup) {
        findFacilityGroup.categoryGroups.forEach(category => {
          tenant.categoryGroups.forEach(tenantCategory => {
            if (category.id === tenantCategory.id) {
              findFacilityGroup.tenants.push(tenant);
            }
          });
        });
      }
    });

    // ! 전체 point 의 category
    categoryGroupStore.setCategoriesMap('TENANT_SEARCH', tenantSearchGroupsMap);
    categoryGroupStore.setCategoriesMap('MAP_FACILITY', mapFacilityGroupsMap);

    // 모든 points 의 매장 검색용 tenants
    const searchTenants: TenantWithPointId[] = [];

    // 모든 points 의 층별안내 편의시설용 tenants
    const mapFacilityTenants: TenantWithPointId[] = [];

    tenants?.forEach(tenant => {
      tenant.categoryGroups.forEach(category => {
        if (category.feature === 'TENANT_SEARCH') {
          searchTenants.push(tenant);
        }

        if (category.feature === 'MAP_FACILITY') {
          mapFacilityTenants.push(tenant);
        }
      });
    });

    tenantStore.setSearchTenants(searchTenants);
    tenantStore.setMapFacilityTenants(mapFacilityTenants);

    // ! 내 point 의 category tenant list
    const findTenantSearchTenants: CategoryGroupWithTenants | undefined = tenantSearchGroupsMap.get(pointId);
    const findMapFacilityTenants: CategoryGroupWithTenants | undefined = mapFacilityGroupsMap.get(pointId);

    // 내 point 의 매장검색용 tenant list
    if (findTenantSearchTenants) {
      categoryGroupStore.setCategoryList('TENANT_SEARCH', findTenantSearchTenants);
    }

    // 내 point 의 층별안내 카테고리용 tenant list
    if (findMapFacilityTenants) {
      categoryGroupStore.setCategoryList('MAP_FACILITY', findMapFacilityTenants);
    }
  };

  return { getCategoriesAndTenantsByFeature };
};
export default useCategory;
