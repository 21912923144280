import axios, { AxiosError, AxiosInstance } from 'axios';

interface CustomInstance extends AxiosInstance {
  get<T>(...params: Parameters<AxiosInstance['get']>): Promise<T>;
  post<T>(...params: Parameters<AxiosInstance['post']>): Promise<T>;
}

// WEB client 로 로그인 시
const useNoAuthAxios = () => {
  const api: CustomInstance = axios.create({
    baseURL: process.env.REACT_APP_MOBILE_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'API-KEY': process.env.REACT_APP_API_KEY,
    },
  });

  api.interceptors.request.use(
    async config => {
      return config;
    },
    error => Promise.reject(error),
  );

  api.interceptors.response.use(
    response => response.data.data,
    (error: AxiosError) => {
      throw error;
    },
  );

  return { api };
};

export default useNoAuthAxios;
