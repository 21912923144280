/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
import * as StompJs from '@stomp/stompjs';
import { errorConsole, logConsole, warnConsole } from 'component/common/console/console';
import { DESKTOP_ENVIRONMENT } from 'constants/environment';
import { DESTINATION, RECONNECT_DELAY, PAGE_USAGE_DELAY } from 'data/socket';
import { TokenType } from 'data/token';
import useCategory from 'hooks/category/useCategory';
import useCookie from 'hooks/cookie/useCookie';
import usePoiContentKeyword from 'hooks/keyword/usePoiContentKeyword';
import useLanguage from 'hooks/language/useLanguage';
import useMenu from 'hooks/menu/useMenu';
import useTenant from 'hooks/tenant/useTenant';
import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import SockJS from 'sockjs-client';
import useMapStore from 'stores/map';
import { LangCode } from 'types/common/language.type';
import { Machine } from 'types/common/machine.type';
import { ConnectType, SocketCommand } from 'types/common/socket.type';
import { TenantWithPointId } from 'types/common/tenant.type';
import { getTimeStamp, getUnix, getUnixMillis } from 'utils/dateTime/timeStamp';
import useMenuStore from 'stores/menu';
import useDomainStore from 'stores/domain';
import useWebInfoStore from 'stores/webInfo';
import useMachine from './machine/useMachine';
import usePoint from './point/usePoint';
import useScreensaver from './screensaver/useScreensaver';
import useStats from './stats/useStats';
import useToken from './token/useToken';

const useFetchServerApis = () => {
  // hook
  const webAuthManager = useCookie();
  const desktopAuthManager = useToken();

  const langManager = useLanguage();
  const menuManager = useMenu();
  const machineManager = useMachine();
  const tenantManager = useTenant();
  const screensaverManager = useScreensaver();
  const pointManager = usePoint();
  const categoryManager = useCategory();
  const keywordManager = usePoiContentKeyword();
  const statisticsManager = useStats(); // 통계

  // store
  const mapStore = useMapStore();
  const menuStore = useMenuStore();
  const domainStore = useDomainStore();
  const webInfoStore = useWebInfoStore();

  // state
  const [fetchApiLoaded, setFetchApiLoaded] = useState(false);
  const [machine, setMachine] = useState<Machine | null>(null);
  const [machineAccessToken, setMachineAccessToken] = useState<string>(''); // WEB, DESKTOP 공통 기기 토큰

  // ref
  const stompClientRef = useRef<StompJs.Client>();
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Timer의 타입을 명시적으로 지정
  const pageIdRef = useRef(''); // pageid의 최신 값을 유지하는 ref

  // api fetch
  const fetchAllOnce = async (mainLang: LangCode, pointId: string) => {
    await Promise.all([
      screensaverManager.getScreensaver(),
      pointManager.getPoints(mainLang, pointId),
      menuManager.getMenus(mainLang),
    ]);

    // 기기 사용량 데이터 수집
    await statisticsManager.postUsages();
  };

  // web mode : login 이후 데이터 fetch
  const fetchDataAfterLogin = async () => {
    // 1. 언어 목록, 메인 언어 조회
    const mainLang: LangCode | undefined = await langManager.getLanguages();

    if (mainLang) {
      // 2. 기기 조회
      const machineData: Machine | undefined = await machineManager.getMachineAndMapData(mainLang);

      if (machineData) {
        // 3. 테넌트 목록 조회
        const rawTenantsWithPointId: TenantWithPointId[] | undefined = await tenantManager.getRawTenants(mainLang);

        if (rawTenantsWithPointId) {
          // 4. 카테고리 조회
          await categoryManager.getCategoriesAndTenantsByFeature(mainLang, machineData.point.id, rawTenantsWithPointId);
          // 4. 키워드 조회
          await keywordManager.getPoiContentKeywords(rawTenantsWithPointId);
        }

        // 5. 나머지 apis 조회
        await fetchAllOnce(mainLang, machineData.point.id);

        setMachine(machineData);
        setFetchApiLoaded(true);

        logConsole('> 4. internal api fetch 완료 :', getTimeStamp());
      }
    }
  };

  /**
   * DESKTOP 모드일 경우 fetch
   *
   * 1. token fetch
   * 2. api fetch
   */
  const fetchDesktopMode = async () => {
    const token = await desktopAuthManager.getToken();

    if (token) {
      // DESKTOP accessToken
      setMachineAccessToken(token.token);
      await fetchDataAfterLogin();
    }
  };

  /**
   * WEB 모드일 경우 fetch
   */
  const fetchWebMode = async () => {
    await fetchDataAfterLogin();
  };

  // 백엔드 통신 함수, localStorage에 저장된 machineType에 따라 분기 처리합니다.
  const fetchApis = async () => {
    // DESKTOP 모드
    if (DESKTOP_ENVIRONMENT) {
      logConsole('> 2. fetch desktop server apis');
      await fetchDesktopMode();
    }

    // WEB 모드
    if (webInfoStore.webInfo && domainStore.domainType === 'WEB') {
      logConsole('> 2. ** WEB ** fetch web server apis');
      await fetchWebMode();
    }
  };

  // socket 연결됐을 경우
  const socketConnected = (machineId: string) => {
    const resetSubscribeUrl = DESTINATION.RESET.replace(':machineId', machineId);

    if (stompClientRef.current) {
      stompClientRef.current.subscribe(resetSubscribeUrl, ({ body }) => {
        if (body) {
          if (body === SocketCommand.RESET) {
            window.location.reload();
          }
        }
      });
    }
  };

  // socket 연결 끊겼을 경우
  const socketClosed = async () => {
    // 소켓이 닫혔을 때 인터벌 정리
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    warnConsole('socket closed! 🤢');
  };

  /**
   * @desc 기기 통계 데이터를 적재한다.
   */
  const sendPageInfo = () => {
    // stompClientRef.current 연결이 유효한 경우에 수행
    if (stompClientRef?.current?.connected && machine) {
      try {
        stompClientRef.current.publish({
          headers: {
            Authorization: machineAccessToken,
            pointId: machine.point.id,
            machineId: machine.id,
            connectType: DESKTOP_ENVIRONMENT ? ConnectType.DESKTOP_MACHINE : ConnectType.WEB_MACHINE,
          },
          destination: DESTINATION.PAGE_USAGE,
          body: JSON.stringify({
            page: pageIdRef.current, // 최신 pageid 값 사용
            timestamp: getUnixMillis(),
          }),
        });
      } catch (error) {
        errorConsole('page info publish', error);
      }
    } else {
      warnConsole('socket disconnected! or no machine info! 🤢');
    }
  };

  /**
   * @desc 페이지 사용률 통계 PAGE_USAGE_DELAY초 마다 호출
   */
  const intervalLoadMetrics = () => {
    // 기존 interval이 있으면 정리
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    sendPageInfo(); // 최초 호출

    intervalRef.current = setInterval(() => {
      sendPageInfo();
    }, PAGE_USAGE_DELAY); // 10초마다 호출
  };

  // socket 연결 확인
  const handleConnectSocket = () => {
    // TODO: 소켓이 이미 존재하는지 확인하고 방어코드 추가
    const socketUrl = process.env.REACT_APP_WS_URL;

    if (machineAccessToken && machine) {
      // 클라이언트 초기화
      const client = new StompJs.Client({
        webSocketFactory: () => new SockJS(`${socketUrl}/ws-notification`), // SockJS 연결
        // debug: str => {
        //   console.log(str); // 디버그 로그 출력
        // },
        beforeConnect: () => {
          client.connectHeaders = {
            Authorization: machineAccessToken,
            pointId: machine.point.id,
            machineId: machine.id,
            connectType: DESKTOP_ENVIRONMENT ? ConnectType.DESKTOP_MACHINE : ConnectType.WEB_MACHINE,
          };
        },
        reconnectDelay: RECONNECT_DELAY,
        onConnect: () => {
          console.log('Connected to WebSocket');
          socketConnected(machine.id); // 기기 재시작
          intervalLoadMetrics(); // interval 페이지 통계
        },
        // 연결이 정상적으로 종료할때, 성공적으로 종료 되었음 , 연결이 끊겼을 때 실행할 로직을 처리하는 데 사용
        onDisconnect: () => {
          console.log('STOMP disconnected !!! socketConnect try');
          // 연결 종료 후의 처리 로직
          socketConnected(machine.id); // 기기 재시작
        },
        // WebSocket 연결이 닫혔을 때 호출됩니다.
        onWebSocketClose: socketClosed,
      });
      // 클라이언트 활성화
      client.activate();
      stompClientRef.current = client;
    }
  };

  /**
   * WEB accessToken
   */
  useEffect(() => {
    if (!DESKTOP_ENVIRONMENT) {
      setMachineAccessToken(webAuthManager.getCookie(TokenType.WEB));
    }
  }, [webAuthManager.getCookie(TokenType.WEB)]);

  // socket 연결
  useEffect(() => {
    // 컴포넌트 마운트 시 소켓 연결 시작
    handleConnectSocket();

    // 컴포넌트 언마운트 시 연결 해제 및 interval 클리어
    return () => {
      if (stompClientRef) {
        stompClientRef.current?.deactivate();
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [machineAccessToken, machine]);

  // 키오스크 실행
  // dependency : cookie, map script loaded 여부
  useEffect(() => {
    // map sdk를 불러오는 스크립트가 load된 이후에 api 통신 시작
    if (mapStore.loadMapScript) {
      fetchApis();
    }
  }, [webAuthManager.getCookie(TokenType.WEB), mapStore.loadMapScript, webInfoStore.webInfo, domainStore.domainType]);

  // 현재 매뉴가 변경될 때 마다 socket send
  useEffect(() => {
    pageIdRef.current = menuStore.curMenu;
    if (stompClientRef?.current?.connected) {
      sendPageInfo();
    }
  }, [menuStore.curMenu]);

  return { fetchApiLoaded, machineAccessToken };
};

export default useFetchServerApis;
