export enum ScreenType {
  verti = 'verti',
  hori = 'hori',
  square = 'square',
  browser = 'browser',
}

export enum PathType {
  login = '/login',
  noLoginWis = '/wis',
  noLoginCafeShow = '/cafeshow',

  notFound = 'error/not-found',
  error = 'error',
  desktopError = 'desktop-error',
  resolutionError = 'error/resolution',
  loading = 'loading',

  home = 'home',
  floor = 'floor',
  tenant = 'tenant',
  customBoard = 'custom-board',
}
