import { EndPoint } from 'data/endPoints';
import useCustomBoardStore from 'stores/customBoard';
import useDomainStore from 'stores/domain';
import useMenuStore from 'stores/menu';
import useWebInfoStore from 'stores/webInfo';
import { LangCode } from 'types/common/language.type';
import { CustomPage } from 'types/feature/customBoard.type';
import { MainMenu } from 'types/feature/menu.type';
import useAxios from 'utils/axios/fetchAxios';
import useNoAuthAxios from 'utils/axios/noAuthFetchAxios';
import { convertMultiLang } from 'utils/multi-lang/convertMultiLang';

const useMenu = () => {
  // hook
  const apiManager = useAxios();
  const noAuthApiManager = useNoAuthAxios();

  // store
  const menuStore = useMenuStore();
  const customBoardStore = useCustomBoardStore();
  const domainStore = useDomainStore();
  const webInfoStore = useWebInfoStore();

  /**
   * @desc 메인 메뉴를 조회한다.
   * @param {LangCode} mainLang
   * @async
   */
  const getMenus = async (mainLang: LangCode) => {
    // domainType 에 따라 api 분기 WEB
    if (domainStore.domainType === 'WEB') {
      const pointId = webInfoStore.webInfo?.point.id;
      if (!pointId) return;
      const menus = await noAuthApiManager.api.get<MainMenu[]>(EndPoint.menusV2ByMobile, {
        params: {
          pointId,
        },
      });
      if (menus) {
        const convertedMenus = menus.map(menu => {
          const mainMenu = { ...menu };
          mainMenu.name = convertMultiLang(menu.name, mainLang);
          return mainMenu;
        });

        menuStore.setMenus(convertedMenus);

        /**
         * 메뉴 api의 response에서 배열 아이템 안에 있는 customPageId 값을 이용해 custom-page api와 통신한다.
         * custom-page api response 또한 zustand에 저장
         */
        const customPageMap = new Map<string, CustomPage>();

        convertedMenus.forEach(async menu => {
          if (menu.customPageId) {
            await noAuthApiManager.api
              .get<CustomPage>(EndPoint.customPageByMobile.replace(':customPageId', menu.customPageId))
              .then(response => {
                if (menu.customPageId) {
                  const convertedCustomBoards = response.contents.map(board => {
                    const customBoard = { ...board };
                    customBoard.title = convertMultiLang(board.title, mainLang);

                    return customBoard;
                  });

                  customPageMap.set(menu.customPageId, {
                    ...response,
                    contents: convertedCustomBoards,
                  });

                  customBoardStore.setCustomPages(customPageMap);
                }
              });
          }
        });
      }
    } else {
      // domainType 에 따라 api 분기 DESKTOP
      const menus = await apiManager.api.get<MainMenu[]>(EndPoint.menusV2);
      if (menus) {
        const convertedMenus = menus.map(menu => {
          const mainMenu = { ...menu };
          mainMenu.name = convertMultiLang(menu.name, mainLang);
          return mainMenu;
        });

        menuStore.setMenus(convertedMenus);

        /**
         * 메뉴 api의 response에서 배열 아이템 안에 있는 customPageId 값을 이용해 custom-page api와 통신한다.
         * custom-page api response 또한 zustand에 저장
         */
        const customPageMap = new Map<string, CustomPage>();

        convertedMenus.forEach(async menu => {
          if (menu.customPageId) {
            await apiManager.api
              .get<CustomPage>(EndPoint.customPage.replace(':customPageId', menu.customPageId))
              .then(response => {
                if (menu.customPageId) {
                  const convertedCustomBoards = response.contents.map(board => {
                    const customBoard = { ...board };
                    customBoard.title = convertMultiLang(board.title, mainLang);

                    return customBoard;
                  });

                  customPageMap.set(menu.customPageId, {
                    ...response,
                    contents: convertedCustomBoards,
                  });

                  customBoardStore.setCustomPages(customPageMap);
                }
              });
          }
        });
      }
    }
  };
  return { getMenus };
};
export default useMenu;
