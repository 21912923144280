import { logConsole } from 'component/common/console/console';
import { DESKTOP_ENVIRONMENT } from 'constants/environment';
import MapDraw from 'map/MapDraw';
import { useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useDomainStore from 'stores/domain';
import useMapStore from 'stores/map';
import { PathType } from 'types/common/common.type';
import DateTime from 'utils/dateTime/dateTime';

/**
 * desktop, web 버전별로 map sdk를 읽어오는 url이 달라지기 때문에
 * 동적으로 Script를 생성해서 넣어주는 훅
 */
const useScript = () => {
  // store
  const domainStore = useDomainStore();

  // hook
  const { clientPath } = useParams();
  const { setLoadMapScript } = useMapStore();
  const navigate = useNavigate();
  // index.html에서 환경별로 분기 처리가 되지 않아 동적으로 script 태그를 생성하고
  // 환경별로 sdk url을 넣어줌.
  const createMapSdkScript = () => {
    const script = document.createElement('script');
    script.async = true;
    script.type = 'application/javascript';

    const CURRENT_DATE_TIME = 'YYYYMMDDHHmmss';

    logConsole('> sdk url', process.env.REACT_APP_MAP_API, process.env.REACT_APP_MAP_SDK);

    // DESKTOP
    if (DESKTOP_ENVIRONMENT) {
      if (process.env.REACT_APP_MAP_SDK) {
        script.src = `${process.env.REACT_APP_MAP_SDK}?vdt=${new DateTime().getCurrentDateTime(CURRENT_DATE_TIME)}`;
      }
    }
    // WEB
    else if (process.env.REACT_APP_MAP_API) {
      script.src = `${process.env.REACT_APP_MAP_API}?vdt=${new DateTime().getCurrentDateTime(CURRENT_DATE_TIME)}`;
    }

    document.head.appendChild(script);
    script.onload = () => {
      logConsole('> 1. MAP SDK 스크립트 로드가 완료 되었습니다.');

      setLoadMapScript(true);
      MapDraw.getInstance();
    };

    script.onerror = () => {
      if (domainStore.domainType === 'WEB') {
        navigate(`/web/${clientPath}/${PathType.error}`);
      } else {
        navigate(`/${PathType.error}`, { replace: true });
      }
    };
  };

  useLayoutEffect(() => {
    createMapSdkScript();
  }, []);
};

export default useScript;
