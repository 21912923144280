import MenuQrButton from 'component/common/qr/toggle-button/MenuQrButton';
import { Fragment } from 'react';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import useMachineStore from 'stores/machine';
import useMenuStore from 'stores/menu';
import useScreenTypeStore from 'stores/screenType';
import { ScreenType } from 'types/common/common.type';
import useDomainStore from 'stores/domain';
import MultiLanguageBtn from '../language/MultiLangBtn';
import HoriMallInfo from '../mall-info/HoriMallInfo';
import styles from './MenuBar.module.scss';
import MainButton from './main-button/MainButton';
import MenuButton from './menu-button/MenuButton';

const MenuBar = () => {
  // hook

  // store
  const machineStore = useMachineStore();
  const menuStore = useMenuStore();
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();
  const screenTypeStore = useScreenTypeStore();
  const domainStore = useDomainStore();

  // 메뉴가 홈 또는 모바일이 아닌 것만 필터링
  const filteredMenu = menuStore.mainMenus.filter(menu => !(menu.type === 'home' || menu.type === 'mobile'));

  return domainStore.domainType === 'WEB' ? (
    <div
      style={{
        borderTop: `2px solid ${colorSetStore.sub}`,
        borderBottom: `2px solid ${colorSetStore.sub}`,
      }}
      className={styles.web_container}
    >
      {/* 홈 버튼 */}
      <MainButton menu={menuStore.mainMenus.filter(menu => menu.type === 'home')[0]} />

      {/* 메뉴 버튼 */}
      {filteredMenu.map((menu, index) => {
        return (
          <Fragment key={menu.id}>
            <MenuButton menu={menu} index={index} filteredMenu={filteredMenu} />
          </Fragment>
        );
      })}

      {/* 모바일 QR */}
      {machineStore.machine?.workspace.mobileUsed && (
        <MenuQrButton menu={menuStore.mainMenus.filter(menu => menu.type === 'mobile')[0]} />
      )}

      {/* 가로형일 경우 노출 */}
      {screenTypeStore.screenType === ScreenType.hori && (
        <>
          {/* 가로형 다국어 버튼 */}
          {langStore.languages.length >= 2 && (
            <div className={styles.lang_hori}>
              <MultiLanguageBtn />
            </div>
          )}

          {/* 가로형 운영시간/휴무일/시계 */}
          <HoriMallInfo />
        </>
      )}
    </div>
  ) : (
    <div
      style={{
        borderTop: `2px solid ${colorSetStore.sub}`,
        borderBottom: `2px solid ${colorSetStore.sub}`,
      }}
      className={styles.container}
    >
      {/* 홈 버튼 */}
      <MainButton menu={menuStore.mainMenus.filter(menu => menu.type === 'home')[0]} />

      {/* 메뉴 버튼 */}
      {filteredMenu.map((menu, index) => {
        return (
          <Fragment key={menu.id}>
            <MenuButton menu={menu} index={index} filteredMenu={filteredMenu} />
          </Fragment>
        );
      })}

      {/* 모바일 QR */}
      {machineStore.machine?.workspace.mobileUsed && (
        <MenuQrButton menu={menuStore.mainMenus.filter(menu => menu.type === 'mobile')[0]} />
      )}

      {/* 가로형일 경우 노출 */}
      {screenTypeStore.screenType === ScreenType.hori && (
        <>
          {/* 가로형 다국어 버튼 */}
          {langStore.languages.length >= 2 && (
            <div className={styles.lang_hori}>
              <MultiLanguageBtn />
            </div>
          )}

          {/* 가로형 운영시간/휴무일/시계 */}
          <HoriMallInfo />
        </>
      )}
    </div>
  );
};

export default MenuBar;
