import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useDomainStore from 'stores/domain';
import { extractPath, extractUrlDomainType } from 'utils/extract/extractParams';
import { errorConsole } from 'component/common/console/console';
import useWebLogin from 'hooks/login/useWebLogin';

/**
 * domainType, WebInitInfo 를 셋팅한다.
 */
const useDomainTypeAndWebInitInfo = () => {
  const location = useLocation();
  const domainStore = useDomainStore();
  const webLoginManager = useWebLogin();

  const getWebInfo = async () => {
    const clientPath: string = extractPath(location.pathname);
    try {
      if (!clientPath) return;
      await webLoginManager.getWebInitInfo({ clientPath });
    } catch (error) {
      errorConsole('client path를 불러올 수 없습니다.', error);
    }
  };

  const init = () => {
    const domainType = extractUrlDomainType(location);
    domainStore.setDomainType(domainType);
    if (domainType === 'WEB') getWebInfo();
  };

  useEffect(() => {
    init();
  }, []);
};
export default useDomainTypeAndWebInitInfo;
