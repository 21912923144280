import { create } from 'zustand';

type Store = {
  naviRoutes: any;
  setNaviRoutes: (naviRoutes: any) => void;
  clearNaviRoutes: () => void;
  isNavi: boolean;
  setNavi: (isNavi: boolean) => void;
};

const useNaviRouteStore = create<Store>((set, get) => ({
  naviRoutes: null,
  setNaviRoutes(naviRoutes: any) {
    set(() => ({ naviRoutes }));
  },
  clearNaviRoutes() {
    set(() => ({ naviRoutes: null }));
  },
  isNavi: false,
  setNavi(isNavi: boolean) {
    set(() => ({ isNavi }));
  },
}));

export default useNaviRouteStore;
