import { create } from 'zustand';

type ClientStore = {
  domainType: string;
  setDomainType: (domainType: string) => void;
};

const useDomainStore = create<ClientStore>((set, get) => ({
  domainType: '',
  setDomainType(domainType: string) {
    set({
      domainType,
    });
  },
}));

export default useDomainStore;
