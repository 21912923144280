/* eslint-disable no-console */
import { errorConsole, logConsole } from 'component/common/console/console';
import { DESKTOP_ENVIRONMENT } from 'constants/environment';
import { EndPoint } from 'data/endPoints';
import { floor } from 'lodash';
import MapDraw from 'map/MapDraw';
import { getFloorDataBeforeGetObject } from 'map/control/floor';
import { useNavigate, useParams } from 'react-router-dom';
import useColorSetStore from 'stores/colorSet';
import useDomainStore from 'stores/domain';
import useFloorStore from 'stores/floor';
import useLoadingStore from 'stores/loading';
import useMachineStore from 'stores/machine';
import usePointStore from 'stores/point';
import useWebInfoStore from 'stores/webInfo';
import { PathType } from 'types/common/common.type';
import { LangCode } from 'types/common/language.type';
import { Machine } from 'types/common/machine.type';
import useAxios from 'utils/axios/fetchAxios';
import { getTimeStamp } from 'utils/dateTime/timeStamp';
import { convertMultiLang } from 'utils/multi-lang/convertMultiLang';

const useMachine = () => {
  // hook
  const { api } = useAxios();
  const navigate = useNavigate();
  const { clientPath } = useParams();

  // store
  const floorStore = useFloorStore();
  const machineStore = useMachineStore();
  const loadingStore = useLoadingStore();
  const pointStore = usePointStore();
  const colorSetStore = useColorSetStore();
  const domainStore = useDomainStore();
  const webInfoStore = useWebInfoStore();

  // 저장해놨던 정보로 교체
  // 키오스크 정보
  const getMachineAndMapData = async (mainLang: LangCode): Promise<Machine | undefined> => {
    // domainType 에 따라 api 분기 WEB
    if (domainStore.domainType === 'WEB') {
      if (!webInfoStore.webInfo) return;

      // WEB 은 api 를 호출 하지 않고 webInfoStore로 machine을 셋팅한다.
      const machine: Machine = {
        id: '',
        coordinate: {
          x: webInfoStore.webInfo.location.positionX,
          y: webInfoStore.webInfo.location.positionY,
        },
        status: '',
        floor: webInfoStore.webInfo.floor,
        workspace: webInfoStore.webInfo.workspace,
        point: webInfoStore.webInfo.point,
        map: {
          id: webInfoStore.webInfo.map.id,
          clientId: webInfoStore.webInfo.map.temp1,
          clientSecret: webInfoStore.webInfo.map.temp2,
          version: webInfoStore.webInfo.map.version,
        },
        mapPosition: {
          rotation: webInfoStore.webInfo.location.rotation, // 회전각
          centerPositionX: webInfoStore.webInfo.location.centerPositionX, // 중심 위치 X 좌표
          centerPositionY: webInfoStore.webInfo.location.centerPositionY, // 중심 위치 Y 좌표
          zoom: webInfoStore.webInfo.location.zoom, // 줌 레벨
        },
        theme: webInfoStore.webInfo.theme,
      };

      try {
        if (machine) {
          console.table([{ pointId: machine.point.id, machineId: '' }]);

          machine.floor.name = convertMultiLang(machine.floor.name, mainLang);
          machine.floor.description = convertMultiLang(machine.floor.description, mainLang);

          machineStore.setMachine(machine);
          // 층 관련
          floorStore.setCurrentFloorId(machine.floor.id);
          floorStore.setFloorName(machine.floor.name);

          colorSetStore.setColorSet(machine.theme.colorSet);
          pointStore.setPoint(machine.point);
          pointStore.setSelectedPoint(machine.point);

          // 지도 데이터 가져오기
          const mapData = await MapDraw.getInstance().getMapData({
            mapId: machine.map.id,
            serverType: 'SERVER_STUDIO4',
            clientId: machine.map.clientId,
            clientSecret: machine.map.clientSecret,
            version: machine.map.version,
          });

          // mapData가 없을 경우
          if (!mapData) {
            navigate(`/web/${clientPath}/${PathType.error}`);
            return undefined;
          }

          MapDraw.mapData = mapData;

          /**
           * ! 제거 불가능
           * -> 한번에 모든 층정보 가져오는 방법으로 속도 개선해야 함.
           * await mapData.getAllFloorData(floorIds?:string[])
           * https://api-doc.dabeeomaps.com/classes/DabeeoMapData.DabeeoMapData.html#getAllFloorData
           */
          await getFloorDataBeforeGetObject(mapData);

          loadingStore.setHasMapData(true);

          logConsole('> 3. map data 가져오기 end', getTimeStamp());
          MapDraw.machine = machine;

          return machine;
        }
      } catch (e) {
        errorConsole('dabeeoMaps getMapData error!', e);
      }
    } else {
      // domainType 에 따라 api 분기 DESKTOP
      try {
        const machine = await api.get<Machine>(EndPoint.machinesV2);
        if (machine) {
          console.table([{ pointId: machine.point.id, machineId: machine.id }]);

          machine.floor.name = convertMultiLang(machine.floor.name, mainLang);
          machine.floor.description = convertMultiLang(machine.floor.description, mainLang);

          machineStore.setMachine(machine);
          // 층 관련
          floorStore.setCurrentFloorId(machine.floor.id);
          floorStore.setFloorName(machine.floor.name);

          colorSetStore.setColorSet(machine.theme.colorSet);
          pointStore.setPoint(machine.point);
          pointStore.setSelectedPoint(machine.point);

          // 지도 데이터 가져오기
          let mapData;
          if (DESKTOP_ENVIRONMENT) {
            if (process.env.REACT_APP_ENVIRONMENT === 'local') {
              mapData = await MapDraw.getInstance().getMapData({
                mapId: machine.map.id,
                serverType: 'SERVER_STUDIO4',
                clientId: machine.map.clientId,
                clientSecret: machine.map.clientSecret,
                version: machine.map.version,
              });
            } else {
              mapData = await MapDraw.getInstance().getMapData({
                mapId: machine.map.id,
                localPath: `${process.env.REACT_APP_MAP_URL}/${machine.map.id}/${machine.map.version}`,
                serverType: 'SERVER_STUDIO4_LOCAL',
              });
            }
          } else {
            mapData = await MapDraw.getInstance().getMapData({
              mapId: machine.map.id,
              serverType: 'SERVER_STUDIO4',
              clientId: machine.map.clientId,
              clientSecret: machine.map.clientSecret,
              version: machine.map.version,
            });
          }

          // mapData가 없을 경우
          if (!mapData) {
            navigate(`/${PathType.error}`, { replace: true });

            return undefined;
          }

          MapDraw.mapData = mapData;

          /**
           * ! 제거 불가능
           * -> 한번에 모든 층정보 가져오는 방법으로 속도 개선해야 함.
           * await mapData.getAllFloorData(floorIds?:string[])
           * https://api-doc.dabeeomaps.com/classes/DabeeoMapData.DabeeoMapData.html#getAllFloorData
           */
          await getFloorDataBeforeGetObject(mapData);

          loadingStore.setHasMapData(true);

          logConsole('> 3. map data 가져오기 end', getTimeStamp());

          MapDraw.machine = machine;

          return machine;
        }
      } catch (e) {
        errorConsole('dabeeoMaps getMapData error!', e);
      }
    }
  };

  return {
    getMachineAndMapData,
  };
};

export default useMachine;
