import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useState } from 'react';
import useMachineStore from 'stores/machine';
import useMenuStore from 'stores/menu';
import useScreenTypeStore from 'stores/screenType';
import { ScreenType } from 'types/common/common.type';
import useDomainStore from 'stores/domain';
import { useParams } from 'react-router-dom';
import styles from './MainQr.module.scss';

// footer 에 위치한 main QR
// 모바일로 진입 시 메인페이지로 이동
const MainQr = () => {
  const [url, setUrl] = useState<string>('');

  const machineStore = useMachineStore();
  const menuStore = useMenuStore();
  const screenTypeStore = useScreenTypeStore();
  const domainStore = useDomainStore();
  const { clientPath } = useParams();

  const isVerti = screenTypeStore.screenType === ScreenType.verti || screenTypeStore.screenType === ScreenType.square;

  const handleUrl = () => {
    let urlByEnv = '';
    if (domainStore.domainType === 'WEB') {
      // TODO
      urlByEnv = `${process.env.REACT_APP_QR_BASE_URL}/web/${clientPath}`;
    } else if (domainStore.domainType === 'DESKTOP') {
      if (machineStore.machine) {
        urlByEnv = `${process.env.REACT_APP_QR_BASE_URL}?pointId=${machineStore.machine.point.id}&machineId=${machineStore.machine.id}`;
      }
    }
    setUrl(urlByEnv);
  };

  useEffect(() => {
    handleUrl();
  }, [machineStore.machine]);

  return (
    <div
      // 세로형일 경우 전체 너비의 메뉴 갯수만큼 나눠줘야 한다.
      style={{ width: `${isVerti ? `${`calc(100% / ${menuStore.mainMenus.length})`}` : ''}` }}
      className={styles.container}
    >
      <QRCodeSVG className={styles.qr} value={url} size={80} />
    </div>
  );
};

export default MainQr;
