import axios, { AxiosError, AxiosInstance } from 'axios';
import { DESKTOP_ENVIRONMENT } from 'constants/environment';
import { TokenType } from 'data/token';
import useCookie from 'hooks/cookie/useCookie';
import { useNavigate } from 'react-router-dom';
import { PathType } from 'types/common/common.type';

interface CustomInstance extends AxiosInstance {
  get<T>(...params: Parameters<AxiosInstance['get']>): Promise<T>;
  post<T>(...params: Parameters<AxiosInstance['post']>): Promise<T>;
}

// token 필요한 api 사용 시
const useAxios = () => {
  const navigate = useNavigate();
  const { getCookie } = useCookie();

  const api: CustomInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  api.interceptors.request.use(
    async config => {
      // handle config eg-: setting token
      const requestConfig = config;
      requestConfig.headers.Authorization = DESKTOP_ENVIRONMENT
        ? `Bearer ${localStorage.getItem(TokenType.DESKTOP)}`
        : `Bearer ${getCookie(TokenType.WEB)}`;
      return config;
    },
    error => Promise.reject(error),
  );

  api.interceptors.response.use(
    response => response.data.data,
    (error: AxiosError) => {
      if (DESKTOP_ENVIRONMENT) {
        return navigate(`/${PathType.desktopError}`, { replace: true });
      }
      // 권한 에러시에 error 페이지로 이동
      // if (error.response?.status === 401) {
      //   errorConsole('error', error);
      //   return navigate(`/${PathType.error}`, { replace: true });
      //   // return navigate(PathType.login);
      // }
      return navigate(`/${PathType.error}`, { replace: true });
    },
  );

  return { api };
};

export default useAxios;
