import { useNavigate } from 'react-router-dom';
import { PathType } from 'types/common/common.type';

type AuthCheckProps = {
  screen: React.ReactElement;
  isProtected: boolean;
  isError: boolean;
  isLoading: boolean;
  token: string;
};

const AuthRoute = ({ screen, isProtected, isLoading, isError, token }: AuthCheckProps) => {
  const navigate = useNavigate();

  if (isLoading) {
    navigate(`/${PathType.loading}`, { replace: true });

    return null; // JSX가 아닌 경우 null 반환
  }

  if (!token) {
    navigate(`/${PathType.error}`, { replace: true });
    return null; // JSX가 아닌 경우 null 반환
  }

  return screen;
};
export default AuthRoute;
