export const returnNaviOption = (lineColor: string, solidLineWidth: number) => {
  return {
    // 출발지 아이콘 및 주행선
    origin: {
      markerOptions: {
        iconUrl: '/assets/icon/start.svg',
        width: 30,
        height: 30,
        positionZ: 10,
        visibleIcon: true,
      },
    },

    // 도착지 아이콘 및 주행선
    destination: {
      showTag: false,
      markerOptions: {
        iconUrl: '/assets/icon/arrive.png',
        width: 50,
        height: 70,
        positionZ: 10,
        visibleIcon: true,
      },
    },

    // 기본 주행선 옵션
    defaultLineOption: {
      lineColor,
      solidLineEnabled: true,
      solidLineWidth,
      solidLineCap: 'round',
      solidLineJoin: 'round',
    },
    lineDivide: false, // 네비게이션 선 분할여부 결정 (false 인 경우, defaultLineOption 만 사용)
    lineZ: 10,
  };
};
