import MenuIcon from 'component/common/layout/footer/menu/menu-button/menu-icon/MenuIcon';
import MainQr from 'component/common/qr/container/main/MainQr';
import { COLORS } from 'constants/colors';
import OutsideClickHandler from 'react-outside-click-handler';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import useQrStore from 'stores/qr';
import { MainMenu } from 'types/feature/menu.type';
import useDomainStore from 'stores/domain';
import styles from './MenuQrButton.module.scss';

type Props = {
  menu: MainMenu;
};

const MenuQrButton = ({ menu }: Props) => {
  const qrStore = useQrStore();
  const colorSetStore = useColorSetStore();
  const langStore = useLanguageStore();
  const domainStore = useDomainStore();

  const toggleMainQr = () => {
    if (qrStore.isShowMainQr) {
      return qrStore.closeMainQr();
    }

    return qrStore.openMainQr();
  };

  const color = qrStore.isShowMainQr ? colorSetStore.gnb.text.active : colorSetStore.text.title;

  return domainStore.domainType === 'WEB' ? (
    <div className={styles.web_container}>
      <OutsideClickHandler display='contents' onOutsideClick={qrStore.closeMainQr}>
        <div
          className={`${styles.qr}
          ${qrStore.isShowMainQr && styles.active}`}
          style={{
            background: `${qrStore.isShowMainQr ? colorSetStore.gnb.bg.active : colorSetStore.gnb.bg.inactive}`,
          }}
          onClick={toggleMainQr}
        >
          {/* 아이콘 */}
          {menu?.iconUsed && (
            <div className={styles.icon}>
              <MenuIcon
                iconUri={menu.mainIconUri}
                iconColor={qrStore.isShowMainQr ? COLORS.WHITE : colorSetStore.text.title}
              />
            </div>
          )}

          {/* 문구 */}
          <div style={{ color }} className={styles.text}>
            {menu?.name[langStore.currentLang] || ''}
          </div>

          {/* QR */}
          {qrStore.isShowMainQr && <MainQr />}
        </div>
      </OutsideClickHandler>
    </div>
  ) : (
    <div className={styles.container}>
      <OutsideClickHandler display='contents' onOutsideClick={qrStore.closeMainQr}>
        <div
          className={`${styles.qr}
          ${qrStore.isShowMainQr && styles.active}`}
          style={{
            background: `${qrStore.isShowMainQr ? colorSetStore.gnb.bg.active : colorSetStore.gnb.bg.inactive}`,
          }}
          onClick={toggleMainQr}
        >
          {/* 아이콘 */}
          {menu?.iconUsed && (
            <div className={styles.icon}>
              <MenuIcon
                iconUri={menu.mainIconUri}
                iconColor={qrStore.isShowMainQr ? COLORS.WHITE : colorSetStore.text.title}
              />
            </div>
          )}

          {/* 문구 */}
          <div style={{ color }} className={styles.text}>
            {menu?.name[langStore.currentLang] || ''}
          </div>

          {/* QR */}
          {qrStore.isShowMainQr && <MainQr />}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default MenuQrButton;
