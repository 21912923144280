import LOCATION_WHITE from 'assets/popup/tenant/button/location-white.svg';
import NAVIGATION_WHITE from 'assets/popup/tenant/button/navigation-white.svg';
import DisabledScreen from 'component/common/disable/DisabledScreen';
import useStats from 'hooks/fetch/stats/useStats';
import MapDraw from 'map/MapDraw';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useMapActionStore from 'stores/action';
import useColorSetStore from 'stores/colorSet';
import useLoadingStore from 'stores/loading';
import useNaviRouteStore from 'stores/naviRoute';
import useQrStore from 'stores/qr';
import useTenantStore from 'stores/tenant';
import useTransportStore from 'stores/transport';
import { ButtonType } from 'types/common/button.type';
import { PathType } from 'types/common/common.type';
import useDomainStore from 'stores/domain';
import styles from './TenantButton.module.scss';

type TenantButtonProps = {
  closeModal: () => void;
  type: ButtonType;
};

const TenantButton = ({ closeModal, type }: TenantButtonProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { postTenantUsage } = useStats();
  const { clientPath } = useParams();

  const tenantStore = useTenantStore();
  const qrStore = useQrStore();
  const transportStore = useTransportStore();
  const mapActionStore = useMapActionStore();
  const loadingStore = useLoadingStore();
  const themeStore = useColorSetStore();
  const naviRouteStore = useNaviRouteStore();
  const domainStore = useDomainStore();

  // 복층 길안내 여부
  const multiFloor = tenantStore.currentTenant?.content.floorId !== MapDraw.machine.floor.id;

  // 위치확인
  const handleLocation = () => {
    qrStore.setShowNaviQr(false);
    mapActionStore.setMapAction('location', true);
    // domainType 에 따라 link 분기
    if (domainStore.domainType === 'WEB') {
      navigate(`/web/${clientPath}/${PathType.floor}`);
    } else {
      navigate(`/${PathType.floor}`, { replace: true });
    }

    closeModal();
  };

  // 단층 길찾기 일 경우
  const handleSameFloorNavi = () => {
    // 추천경로에서 층을 비교했을 때 층이 달라진다면 층이 끊어져있다고 간주 (ex) 가산)
    const findDiffFloor = naviRouteStore.naviRoutes.recommendation.locations.find(
      (location: any) => location.floorId !== tenantStore.currentTenant?.content.floorId,
    );

    // 층이 끊어져 있는 경우 이동수단 팝업을 노출한다.
    if (findDiffFloor) {
      mapActionStore.setMapAction('transport', true);
      return;
    }

    // 층이 끊어져 있지 않는 경우
    mapActionStore.setMapAction('navigation', true);
    closeModal();
  };

  // 길찾기
  const handleNavigate = () => {
    qrStore.setShowNaviQr(true);
    // domainType 에 따라 link 분기
    if (domainStore.domainType === 'WEB') {
      navigate(`/web/${clientPath}/${PathType.floor}`);
    } else {
      navigate(`/${PathType.floor}`, { replace: true });
    }

    // 복층일 경우
    if (multiFloor) {
      mapActionStore.setMapAction('transport', true);
      loadingStore.setActionLoading(false);
      return;
    }

    // 단층 길찾기 일 경우
    handleSameFloorNavi();
    loadingStore.setActionLoading(false);
  };

  const handleClick = async () => {
    // 위치확인
    if (type === ButtonType.location) {
      // 매장 사용량 수집
      await postTenantUsage(tenantStore.currentTenant?.id || '', 'LOCATION');

      handleLocation();
      return;
    }

    // 길찾기
    if (type === ButtonType.navigation) {
      loadingStore.setActionLoading(true);

      // 매장 사용량 수집
      await postTenantUsage(tenantStore.currentTenant?.id || '', 'NAVIGATION');

      handleNavigate();

      // 모의주행 시작
      naviRouteStore.setNavi(true);
    }
  };

  const locationButton = type === ButtonType.location;

  return (
    <>
      <button
        style={{ background: `${locationButton ? themeStore.sub : themeStore.main}` }}
        disabled={transportStore.isLoadingTransport}
        type='button'
        onClick={handleClick}
        className={`${styles.button} ${locationButton ? styles.location_bg : styles.navi_bg}`}
      >
        <img
          src={locationButton ? LOCATION_WHITE : NAVIGATION_WHITE}
          alt={`${locationButton ? '위치확인' : '길찾기'} 아이콘`}
        />
        <div>{locationButton ? t(`button.location`) : t(`button.navigation`)}</div>
      </button>

      {transportStore.isLoadingTransport && <DisabledScreen />}
    </>
  );
};
export default TenantButton;
