/* eslint-disable prefer-destructuring */
import { errorConsole } from 'component/common/console/console';
import usePoint from 'hooks/fetch/point/usePoint';
import useFloor from 'hooks/floor/useFloor';
import MapDraw from 'map/MapDraw';
import { clearMap, clearMyLocation } from 'map/control/common/clear';
import { useRef } from 'react';
import useMapActionStore from 'stores/action';
import useBuildingStore from 'stores/building';
import useCategoryGroupStore from 'stores/categoryGroup';
import useColorSetStore from 'stores/colorSet';
import useFloorStore from 'stores/floor';
import useLoadingStore from 'stores/loading';
import useNaviRouteStore from 'stores/naviRoute';
import usePointStore from 'stores/point';
import usePopupStore from 'stores/popup';
import useTenantStore from 'stores/tenant';
import useTransportStore from 'stores/transport';
import { CategoryGroupWithTenants } from 'types/common/category.type';
import { Floor } from 'types/common/floor.type';
import { changeMapPointSettingWithoutCamera } from 'utils/map/changeMapSetting';
import { returnNaviOption } from 'utils/option/returnNaviOption';

// 경로안내
const useMapNavigation = () => {
  const mapActionStore = useMapActionStore();
  const tenantStore = useTenantStore();
  const floorStore = useFloorStore();
  const loadingStore = useLoadingStore();
  const transportStore = useTransportStore();
  const popupStore = usePopupStore();
  const colorSetStore = useColorSetStore();
  const pointStore = usePointStore();
  const categoryStore = useCategoryGroupStore();
  const buildingStore = useBuildingStore();
  const naviRouteStore = useNaviRouteStore();

  const { findFloorNameByBuildingId } = useFloor();
  const { setPointNameByBuildingId } = usePoint();

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // 3초 후 팝업 닫기
  const closePopupAfterSeconds = () => {
    timerRef.current = setTimeout(() => {
      popupStore.setOpenFloorChangedPopup(false);
    }, 3000);
  };

  // 팝업 타이머 제거
  const clearPopupTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    popupStore.setOpenFloorChangedPopup(false);
  };

  // 층 변경 팝업 열기
  const openFloorChangedPopup = () => {
    popupStore.setOpenFloorChangedPopup(true);
    closePopupAfterSeconds();
  };

  // 층 목록 변경
  const changeFloors = (buildingId: string) => {
    const findFloors: Floor[] | undefined = floorStore.buildingFloorsMap?.get(buildingId);

    if (findFloors) {
      floorStore.setFloors(findFloors);
    }
  };

  // 층 이름 변경
  const changeFloorName = (floorId: string, buildingId: string) => {
    const floorName = findFloorNameByBuildingId(floorId, buildingId);

    if (floorName) {
      floorStore.setFloorName(floorName);
    }
  };

  // 카테고리
  const changeCategory = (pointId: string) => {
    if (categoryStore.mapFacilityCategoriesMap) {
      const findCategories: CategoryGroupWithTenants | undefined = categoryStore.mapFacilityCategoriesMap.get(pointId);

      if (findCategories) {
        categoryStore.setCategoryList('MAP_FACILITY', findCategories);
      }
    }
  };

  // 포인트
  const changePoint = (buildingId: string) => {
    const findPoint = pointStore.buildingPointsMap?.get(buildingId);

    if (findPoint) {
      pointStore.setSelectedPoint(findPoint);
      // 카테고리
      changeCategory(findPoint.id);
    }
  };

  /**
   * @desc floor-changing 이벤트 발생 시 실행되는 함수
   * @param e floor-changing 이벤트
   */
  const handleFloorChanging = (e: any) => {
    const buildingId = e.detail.floor.next.buildingId;
    const floorId = e.detail.floor.next.id;

    // 빌딩 이름
    setPointNameByBuildingId(buildingId);
    // 빌딩 타입
    buildingStore.setBuildingType(e.detail.floor.next.isOutdoor ? 'OUTDOOR' : 'INDOOR');

    // 층
    floorStore.setCurrentFloorId(floorId);
    // 층 목록
    changeFloors(buildingId);
    // 층 이름
    changeFloorName(floorId, buildingId);

    // 팝업 관리
    openFloorChangedPopup();

    // 포인트
    changePoint(buildingId);
  };

  /**
   * @desc floor-changed 이벤트 발생 시 실행되는 함수
   * @param e floor-changed 이벤트
   */
  const handleFloorChanged = (e: any) => {
    const findPoint = pointStore.buildingPointsMap?.get(e.detail.buildingId);
    if (findPoint) {
      // 카메라 설정 및 센터값 변경
      changeMapPointSettingWithoutCamera(findPoint);
    }
  };

  /**
   * @desc navi-complete 이벤트 발생 시 실행되는 함수
   * @param e navi-complete 이벤트
   */
  const handleNaviComplete = () => {
    clearPopupTimer();
    mapActionStore.resetMapActions();
    naviRouteStore.clearNaviRoutes();

    // 모의주행 완료
    naviRouteStore.setNavi(false);

    // 층 변경 중 발생하는 이벤트 구독 취소
    MapDraw.mapContainer.removeEventListener('floor-changing', handleFloorChanging);
    // 층 변경 후 발생하는 이벤트 구독 취소
    MapDraw.mapContainer.removeEventListener('floor-changed', handleFloorChanged);
    // 모의주행 끝난 후 발생하는 이벤트 구독 취소
    MapDraw.mapContainer.removeEventListener('navi-complete', handleNaviComplete);
  };

  // 길찾기 시작
  const startNavi = async (routes: any) => {
    await MapDraw.map.routeSimulation.set(
      routes[transportStore.transport],
      returnNaviOption(colorSetStore.navi, Number(MapDraw.machine?.workspace.lineThickness || 13)),
    );

    await MapDraw.map.routeSimulation.start({
      //  도착지 옵션
      destOption: {
        opacity: 0.8,
        isAnimate: true,
        duration: 1000,
        isRepeat: false,
        isYoyo: false,
      },
      changeFloorDelay: 0,
      speedRate: Number(MapDraw.machine?.workspace.navigationSpeed || 35),
      ease: MapDraw.machine?.workspace.navigationSpeedOption || 'LINEAR',
      removeIcon: true,
      markerOptions: {
        iconUrl: '/assets/icon/na.svg',
        width: 50,
        height: 50,
      },
      enableFloorMotionCSS: true,
      floorMotionDuration: 1500,
      zoom: MapDraw.machine.mapPosition.zoom || 21,
    });

    // 층 변경 중 발생하는 이벤트 구독
    MapDraw.mapContainer.addEventListener('floor-changing', handleFloorChanging);
    // 층 변경 후 발생하는 이벤트 구독
    MapDraw.mapContainer.addEventListener('floor-changed', handleFloorChanged);
    // 모의주행 끝난 후 발생하는 이벤트 구독
    MapDraw.mapContainer.addEventListener('navi-complete', handleNaviComplete);
  };

  // 길찾기
  const triggerNavigation = async () => {
    try {
      if (!tenantStore.currentTenant) {
        return;
      }

      loadingStore.setActionLoading(true);

      clearMap();
      clearMyLocation();

      const machineFloorId = MapDraw.machine.floor.id;
      const machineBuildingId = MapDraw.machine.point.buildingId;

      // 길찾기 시작 시 현재위치(기기)로 상태값 변경
      floorStore.setCurrentFloorId(machineFloorId);
      changeFloors(machineBuildingId);
      changeFloorName(machineFloorId, machineBuildingId);

      // 포인트
      // 첫 기기 세팅과 동일하게 변경
      changePoint(machineBuildingId);

      // 길찾기 시작
      startNavi(naviRouteStore.naviRoutes);

      loadingStore.setActionLoading(false);
    } catch {
      errorConsole('navigation error');
    } finally {
      loadingStore.setActionLoading(false);
    }
  };

  return {
    triggerNavigation,
  };
};
export default useMapNavigation;
