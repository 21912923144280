import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useState } from 'react';
import useMachineStore from 'stores/machine';
import useTenantStore from 'stores/tenant';
import useDomainStore from 'stores/domain';
import { useParams } from 'react-router-dom';
import styles from './TenantDetailQr.module.scss';

// tenant 상세 팝업 QR
// 모바일로 진입 시 지도에서 해당 tenant 의 위치로 이동 + marker
const TenantDetailQr = () => {
  const [url, setUrl] = useState<string>('');

  const machineStore = useMachineStore();
  const tenantStore = useTenantStore();
  const domainStore = useDomainStore();
  const { clientPath } = useParams();

  const handleUrl = () => {
    let urlByEnv = '';
    if (domainStore.domainType === 'WEB') {
      if (tenantStore.currentTenant) {
        urlByEnv = `${process.env.REACT_APP_QR_BASE_URL}/web/${clientPath}?type=location&destTenantId=${tenantStore.currentTenant.id}`;
      }
    } else if (domainStore.domainType === 'DESKTOP') {
      if (machineStore.machine && tenantStore.currentTenant) {
        urlByEnv = `${process.env.REACT_APP_QR_BASE_URL}?type=location&pointId=${machineStore.machine.point.id}&machineId=${machineStore.machine.id}&destTenantId=${tenantStore.currentTenant.id}`;
      }
    }
    setUrl(urlByEnv);
  };

  useEffect(() => {
    handleUrl();
  }, [machineStore.machine, tenantStore.currentTenant]);

  return (
    <div className={styles.qr_svg_wrapper}>
      <QRCodeSVG className={styles.qr_svg} value={url} size={300} />
    </div>
  );
};

export default TenantDetailQr;
