import { EndPoint } from 'data/endPoints';
import useDomainStore from 'stores/domain';
import useWebInfoStore from 'stores/webInfo';
import { Web } from 'types/feature/webInfo.type';
import useNoAuthAxios from 'utils/axios/noAuthFetchAxios';

// domain type 이  WEB 인 경우 WebInitInfo 를 셋팅한다.
const useWebLogin = () => {
  // store
  const domainStore = useDomainStore();
  const noAuthApiManager = useNoAuthAxios();
  const webInfoStore = useWebInfoStore();

  const getWebInitInfo = async ({ clientPath }: { clientPath: string }) => {
    const { domainType } = domainStore;

    const result = await noAuthApiManager.api.get<Web>(EndPoint.clientPath, {
      params: {
        clientPath,
        domainType,
      },
    });

    if (result) {
      webInfoStore.setWebInfo(result);
      return result;
    }
  };

  return {
    getWebInitInfo,
  };
};

export default useWebLogin;
