import { COLORS } from 'constants/colors';
import _ from 'lodash';
import { Link, useLocation, useParams } from 'react-router-dom';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import useQrStore from 'stores/qr';
import { PathType } from 'types/common/common.type';
import { MainMenu } from 'types/feature/menu.type';
import useDomainStore from 'stores/domain';
import styles from './MenuButton.module.scss';
import MenuIcon from './menu-icon/MenuIcon';

type Props = {
  menu: MainMenu;
  index: number;
  filteredMenu: MainMenu[];
};

const MenuButton = ({ menu, index, filteredMenu }: Props) => {
  // store
  const colorSetStore = useColorSetStore();
  const qrStore = useQrStore();
  const langStore = useLanguageStore();
  const domainStore = useDomainStore();

  // hook
  const location = useLocation();
  const { clientPath } = useParams();

  const splitUrl = (url: string) => {
    // '/'로 URL을 분리하고 빈 값을 제거
    const parts = url.split('/').filter(Boolean);
    // 마지막 부분
    const page = parts[parts.length - 1]; // `floor`

    return page;
  };

  const isActiveMenu = (currentMenu: MainMenu): boolean => {
    // WEB 접근 일 때
    if (domainStore.domainType === 'WEB') {
      const currentPage = splitUrl(location.pathname);
      // 메뉴가 커스텀 보드일 경우
      if (currentPage === PathType.customBoard) {
        if (!location.state) {
          return false;
        }

        if (location.state.pageId === currentMenu.customPageId) {
          return true;
        }
        return false;
      }

      // 메뉴가 커스텀 보드가 아닐 경우
      if (currentPage === currentMenu.type) {
        return true;
      }

      return false;
    }

    // 메뉴가 커스텀 보드일 경우
    if (currentMenu.type === PathType.customBoard) {
      if (!location.state) {
        return false;
      }

      if (location.state.pageId === currentMenu.customPageId) {
        return true;
      }
      return false;
    }
    // 나머지 메뉴일 경우
    if (`/${currentMenu.type}` === location.pathname) {
      return true;
    }

    return false;
  };

  const handleClickMenu = _.debounce(() => {
    qrStore.setShowNaviQr(false);
  }, 400);

  // domain type 에 따른 분기
  const setTo = () => {
    if (domainStore.domainType === 'WEB') {
      return `/web/${clientPath}/${menu.type}`;
    }
    return `${menu.type}`;
  };

  return domainStore.domainType === 'WEB' ? (
    <Link
      onClick={handleClickMenu}
      style={{
        background: `${isActiveMenu(menu) ? colorSetStore.gnb.bg.active : colorSetStore.gnb.bg.inactive}`,
      }}
      className={`${styles.web_menu_wrapper} ${isActiveMenu(menu) && styles.active}
       ${index !== filteredMenu.length - 1 && styles.border_right}
      `}
      to={setTo()}
      state={{ pageId: menu.customPageId }}
    >
      <div className={`${styles.menu} ${isActiveMenu(menu) && styles.active}`}>
        {/* 메뉴 아이콘 */}
        {menu.iconUsed && (
          <div className={styles.icon}>
            <MenuIcon
              iconUri={menu.mainIconUri}
              iconColor={isActiveMenu(menu) ? COLORS.WHITE : colorSetStore.text.title}
            />
          </div>
        )}

        {/* 메뉴 이름 */}
        <div
          style={{ color: `${isActiveMenu(menu) ? colorSetStore.gnb.text.active : colorSetStore.text.title}` }}
          className={styles.menu_text}
        >
          {menu.name[langStore.currentLang]}
        </div>
      </div>
    </Link>
  ) : (
    <Link
      onClick={handleClickMenu}
      style={{
        background: `${isActiveMenu(menu) ? colorSetStore.gnb.bg.active : colorSetStore.gnb.bg.inactive}`,
      }}
      className={`${styles.menu_wrapper} ${isActiveMenu(menu) && styles.active}
       ${index !== filteredMenu.length - 1 && styles.border_right}
      `}
      to={setTo()}
      state={{ pageId: menu.customPageId }}
    >
      <div className={`${styles.menu} ${isActiveMenu(menu) && styles.active}`}>
        {/* 메뉴 아이콘 */}
        {menu.iconUsed && (
          <div className={styles.icon}>
            <MenuIcon
              iconUri={menu.mainIconUri}
              iconColor={isActiveMenu(menu) ? COLORS.WHITE : colorSetStore.text.title}
            />
          </div>
        )}

        {/* 메뉴 이름 */}
        <div
          style={{ color: `${isActiveMenu(menu) ? colorSetStore.gnb.text.active : colorSetStore.text.title}` }}
          className={styles.menu_text}
        >
          {menu.name[langStore.currentLang]}
        </div>
      </div>
    </Link>
  );
};
export default MenuButton;
